import { Component, Inject, OnInit, inject, signal } from '@angular/core';
import { Router } from '@angular/router';
import { UntypedFormBuilder, UntypedFormControl, Validators } from '@angular/forms';
import { Meta, Title } from '@angular/platform-browser';

import { RecoverCertService } from '../../services/recover-cert.service';
import { InicializerService } from 'src/app/services/inicializer.service';
import { AlertService } from 'src/app/services/alert.service';

import { Office } from 'src/app/models/certificados-model';
import { patterns } from 'src/app/helpers/patterns';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'app-recover-cert',
  templateUrl: './recover-cert.component.html',
  styleUrls: ['./recover-cert.component.scss']
})
export class RecoverCertComponent implements OnInit {

  formRecover: any;
  listOffices = signal<Office[]>([]);
  actualDay = signal('')
  minDateForm = signal('')

  private fb = inject(UntypedFormBuilder)
  private initService = inject(InicializerService)
  private recoverCert = inject(RecoverCertService)
  private alertService = inject(AlertService)
  private router = inject(Router)
  private titleService = inject(Title)
  private metaService = inject(Meta)

  constructor(@Inject(DOCUMENT) private dom: any) { }

  ngOnInit(): void {
    this.setSEOData('Recuperar certificados', 'Obten aquí copia de tu Certificado de Tradición y Libertad con el número de matricula inmobiliaria adquirido en nuestra plataforma en los ultimos 30 días.')
    this.setDates()
    this.initForm()
    this.listOffices.set(this.initService.inicializeOffices())
    this.createCanonicalURL("https://certificadostradicionylibertad.co/recuperar-certificado")
  }

  setSEOData(title: string, description: string) {
    this.titleService.setTitle(title);
    this.metaService.updateTag({ name: 'description', content: description });
  }

  createCanonicalURL(url: string) {
    const head = this.dom.getElementsByTagName('head')[0];
    let element: HTMLLinkElement= this.dom.querySelector(`link[rel='canonical']`) || null
    if (element == null) {
      element= this.dom.createElement('link') as HTMLLinkElement;
      head.appendChild(element);
    }
    element.setAttribute('rel','canonical')
    element.setAttribute('href', url)
  }

  initForm() {
    this.formRecover = this.fb.group({
      valOffice: new UntypedFormControl('0', [Validators.required]),
      numMatricula: new UntypedFormControl('', [Validators.required, Validators.pattern(patterns.LETTERS_AND_NUMBERS)]),
      numOffice: new UntypedFormControl('', [Validators.required, Validators.pattern(patterns.ONLY_NUMBERS)]),
      dateSolicitud: new UntypedFormControl('', []),
      fullName: new UntypedFormControl('', [Validators.required, Validators.pattern(patterns.ONLY_LETTERS_AND_SPACE)]),
      email: new UntypedFormControl('', [Validators.required, Validators.pattern(patterns.EMAIL)]),
      phone: new UntypedFormControl('', [Validators.required, Validators.pattern(patterns.ONLY_NUMBERS)])
    })
  }

  get ctlForm() {
    return this.formRecover.controls
  }

  validateInfo() {
    const { dateSolicitud } = this.formRecover.value
    let allYear = new Date()
    let day: string | number = allYear.getDate()
    let month: string | number = allYear.getMonth() + 1
    let year = allYear.getUTCFullYear()
    day < 9 && (day = `0${day}`)
    month < 9 && (month = `0${month}`)
    let yearActual = `${year}-${month}-${day}`
    if (dateSolicitud === yearActual) {
      this.alertService.alert('error', 'Espera al menos 24 horas para realizar la solicitud de recuperacion.')
      this.initForm()
    } else {
      this.onSendInformation()
    }
  }

  onSendInformation() {
    const {
      valOffice, numOffice, dateSolicitud,
      fullName, email, phone
    } = this.formRecover.value
    let sendObj: any = {}
    let idOff = this.listOffices().find(item => item.valueOff == valOffice)
    sendObj.idOffSend = idOff ? idOff.idOff : ''
    sendObj.numberSend = numOffice
    sendObj.dateSend = dateSolicitud
    sendObj.fullNameSend = fullName
    sendObj.emailSend = email
    sendObj.phoneSend = phone
    this.recoverCert.createRecover(sendObj)
    .subscribe(() => {
      this.alertService.alert('success', 'Si los datos indicados coinciden con un certificado adquirido en los últimos 30 días, enviaremos una copia al email indicado en los próximos minutos.')
      this.router.navigate(['/'])
    }, (err: any) => {
      let alternativeMessage = 'Ha ocurrido un error, intenta de nuevo mas tarde.'
      this.alertService.alert('error', err.error?.error || alternativeMessage)
      this.initForm()
    })
  }

  findOffByValue(): void {
    let form = this.formRecover
    const { valOffice, numMatricula } = this.formRecover.controls
    if (form.value.numMatricula.length > 2) {
      let existente = this.listOffices().find(item => item.valueOff == form.value.numMatricula)
      if (existente) {
        valOffice.setValue(existente.valueOff)
      } else {
        numMatricula.setValue('')
        valOffice.setValue('0')
      }
      numMatricula.updateValueAndValidity()
      valOffice.updateValueAndValidity()
    }
  }

  onChangeOffice() {
    let form = this.formRecover
    form.controls.numMatricula.setValue(form.value.valOffice)
  }

  setDates() {
    let allYear = new Date()
    let day: string | number = allYear.getDate()
    let month: string | number = allYear.getMonth() + 1
    let year = allYear.getUTCFullYear()

    day < 9 && (day = `0${day}`)
    month < 9 && (month = `0${month}`)

    this.actualDay.set(`${year}-${month}-${day}`)
    // --------------
    allYear.setDate(allYear.getDate() - 30)
    let lastDay: string | number = allYear.getDate()
    lastDay < 9 && (lastDay = `0${lastDay}`)

    let lastMonth: string | number = allYear.getMonth() + 1
    lastMonth < 9 && (lastMonth = `0${lastMonth}`)

    let lastYear = allYear.getUTCFullYear()
    this.minDateForm.set(`${lastYear}-${lastMonth}-${lastDay}`)
  }

}
