import { Injectable, inject, signal } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { retry, timeout } from 'rxjs';
import { toObservable } from '@angular/core/rxjs-interop';

import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CertificatesService {

  url = environment.apiUrl

  private priceCertificates = signal([])

  priceCertificates$ = toObservable(this.priceCertificates)

  private http = inject(HttpClient)

  getAllCertificates() {
    return this.http
    .get(this.url.concat('products'))
    .pipe(timeout(4000), retry(3))
    .subscribe((data: any) => {
      this.priceCertificates.set(data.certificates)
    })
  }

}
