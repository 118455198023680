import { Injectable, inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { timeout, retry } from 'rxjs';

import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class RecoverCertService {
  url = environment.apiUrl.concat('recover')

  private http = inject(HttpClient)

  createRecover(body: {}) {
    return this.http
    .post(this.url, body)
    .pipe(timeout(5000), retry(3))
  }

}
